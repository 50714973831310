import { State } from "./state";
import { Request } from "../network/request"
import notify from '../helpers/toast-notifications'
import i18n from '../i18n'
export default class driversStore {
    driverState = new State()
    getDriverState = new State()
    editDriverState = new State()
    deleteDriverState = new State()
    driversTable = {
        tableHeaders: [
            'id',
            'name',
            'phone number',
            'land phone number',
            'user name',
            'settings'

        ],
        tableData: [],
        loading: false,
        tableMeta: {
            total: 10,
            page: 1,
        },
    }
    storeDriver(payload) {
        let token = localStorage.getItem('nawader_access_token')
        let request = new Request('post', `/driver`, payload, null, token)
        this.driverState.trigger(async () => {
            return await (await request.send());
        },
            {
                onSuccess: (value) => {
                    this.getDrivers(1)
                    notify(
                        i18n.t('add success'),
                        { timeout: 4000, rtl: true },
                        "success"
                    );

                },
                onError: (error) => { console.log(error) }
            });
    }
    storeEditDriver(payload, payload2) {
        console.log(payload)
        let token = localStorage.getItem('nawader_access_token')
        let request = new Request('put', `/driver/${payload2}`, payload, null, token)
        this.editDriverState.trigger(async () => {
            return await (await request.send());
        },
            {
                onSuccess: (value) => {
                    this.getDrivers(1)
                    notify(
                        i18n.t('edit success'),
                        { timeout: 4000, rtl: true },
                        "success"
                    );
                },
                onError: (error) => { console.log(error) }
            });
    }
    deleteDriver(payload) {
        let token = localStorage.getItem('nawader_access_token')
        let request = new Request('delete', `driver/${payload}`, null, null, token)
        this.deleteDriverState.trigger(async () => {
            return await (await request.send());
        },
            {
                onSuccess: (value) => {
                    this.getDrivers(1)
                    notify(
                        i18n.t('delete success'),
                        { timeout: 4000, rtl: true },
                        "success"
                    );
                },
                onError: (error) => { console.log(error) }
            });
    }
    getDrivers(payload) {
        let token = localStorage.getItem('nawader_access_token')
        let request = new Request('get', `driver?page=${payload}`, null, null, token)
        this.getDriverState.trigger(async () => {
            return await (await request.send());
        },
            {
                onSuccess: (value) => {
                    this.setDriverData(value)
                },
                onError: (error) => { console.log(error) }
            });
    }
    setDriverData(data) {
        this.driversTable.tableData = []
        this.driversTable.tableData = data.drivers
        this.driversTable.tableMeta.total = data.meta.total
        this.driversTable.tableMeta.page = data.meta.current_page

    }
}
export const driver = new driversStore()