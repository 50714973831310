import { State } from "./state";
import { Request } from "../network/request"
import notify from '../helpers/toast-notifications'
import i18n from '../i18n'

export default class driverRoutes {
    driver = []
    student = []
    route = []
    driversTable = {
        returnTableHeaders: [
            'id',
            'name',
            'price',
            'driver route name',
            'driver route address',
            'phone number',
            'settings'
        ],
        goTableHeaders: [
            'select',
            'id',
            'name',
            'price',
            'driver route name',
            'driver route address',
            'phone number',
            'settings'
        ],
        tableData: [],
        loading: false,
        tableMeta: {
            total: 10,
            page: 1,
        },
    }
    studentsRouteTable = {
        tableHeaders: [
            "name",
            "mother name",
            "gender",
            "type",
            "Ranking",
            "Time To Arrive",
            "",
        ],
        tableData: [],
        loading: false,
        tableMeta: {
            total: 10,
            page: 1,
            perPage: 5,

        },
    }
    tripsTable = {
        tableHeaders: [
            "Id",
            "status",
            "created at",
        ],
        tableData: [],
        loading: false,
        tableMeta: {
            total: 10,
            page: 1,
            perPage: 5,

        },
    }
    routeState = new State()
    driverRouteState = new State()
    driverNameState = new State()

    studentNameState = new State()
    routeNameState = new State()
    addStudentRouteState = new State()

    studentsInRouteState = new State()
    routeInfoState = new State()
    deleteRouteState = new State()
    editRouteState = new State()

    tripsState = new State()


    getRoutes(payload) {
        let token = localStorage.getItem('nawader_access_token')
        let request = new Request('get', `/route`, null, payload, token)
        this.routeState.trigger(async () => {
            return await (await request.send());
        },
            {
                onSuccess: (value) => {
                    this.setRouteData(value)
                },
                onError: (error) => { console.log(error) }
            });
    }

    storeDriverRoute(payload) {
        let token = localStorage.getItem('nawader_access_token')
        let term_id = localStorage.getItem('nawader_term')
        Object.assign(payload, { term_id: term_id })
        console.log(payload)
        let request = new Request('post', `/route`, payload, null, token)
        this.driverRouteState.trigger(async () => {
            return await (await request.send());
        },
            {
                onSuccess: (value) => {
                    let params = {
                        page: 1,
                        type: payload.type,
                    }
                    this.getRoutes(params)
                },
                onError: (error) => {
                    console.log(error)
                }
            });
    }
    storeDriverName(payload) {
        let token = localStorage.getItem('nawader_access_token')
        let request = new Request('get', `/driver?name=${payload}`, null, null, token)
        this.driverNameState.trigger(async () => {
            return await (await request.send());
        },
            {
                onSuccess: (value) => {
                    this.driver = value.drivers
                },
                onError: (error) => {
                    console.log(error)
                }
            });
    }
    getRouteInfo(payload) {
        let token = localStorage.getItem('nawader_access_token')
        let request = new Request('get', `/route/${payload}`, null, null, token)
        this.routeInfoState.trigger(async () => {
            return await (await request.send());
        },
            {
                onSuccess: (value) => {
                },
                onError: (error) => {
                    console.log(error)
                }
            });
    }
    getStudentsInRoute(payload) {
        let token = localStorage.getItem('nawader_access_token')
        let request = new Request('get', `/student_route`, null, payload, token)
        this.studentsInRouteState.trigger(async () => {
            return await (await request.send());
        },
            {
                onSuccess: (value) => {
                    this.setStudentsRouteData(value)
                },
                onError: (error) => {
                    console.log(error)
                }
            });
    }
    deleteRoute(payload) {
        let token = localStorage.getItem('nawader_access_token')
        let request = new Request('delete', `route/${payload.id}`, null, null, token)
        this.deleteRouteState.trigger(async () => {
            return await (await request.send());
        },
            {
                onSuccess: (value) => {
                    let params = {
                        page: 1,
                        type: payload.type,
                    }
                    this.getRoutes(params)
                },
                onError: (error) => { console.log(error) }
            });
    }
    editRoute(payload, id) {
        if (!payload.name) {
            delete payload.name
        }
        if (!payload.price) {
            delete payload.price
        }
        if (!payload.driver_id) {
            delete payload.driver_id
        }
        // let data = JSON.stringify(payload);
        let token = localStorage.getItem('nawader_access_token')
        let request = new Request('put', `route/${id}`, payload, null, token)
        this.editRouteState.trigger(async () => {
            return await (await request.send());
        },
            {
                onSuccess: (value) => {
                    let params = {
                        page: 1,
                        type: payload.type,
                    }
                    this.getRoutes(params)
                },
                onError: (error) => { console.log(error) }
            });
    }

    getStudentName(payload) {
        let token = localStorage.getItem('nawader_access_token')
        let request = new Request('get', `/student?auto-complete=${payload}`, null, null, token)
        this.studentNameState.trigger(async () => {
            return await (await request.send());
        },
            {
                onSuccess: (value) => {
                    this.student = value.data
                },
                onError: (error) => {
                    console.log(error)
                }
            });
    }
    getrouteName(payload) {
        let token = localStorage.getItem('nawader_access_token')
        let request = new Request('get', `/route?name=${payload}`, null, null, token)
        this.routeNameState.trigger(async () => {
            return await (await request.send());
        },
            {
                onSuccess: (value) => {
                    this.route = value.routes
                },
                onError: (error) => {
                    console.log(error)
                }
            });
    }
    storeStudentRoute(payload) {
        let token = localStorage.getItem('nawader_access_token')
        let request = new Request('post', `/student_route`, payload, null, token)
        this.addStudentRouteState.trigger(async () => {
            return await (await request.send());
        },
            {
                onSuccess: (value) => {
                    notify(
                        i18n.t('add success'),
                        { timeout: 4000, rtl: true },
                        "success"
                    );
                },
                onError: (error) => {
                    notify(
                        error.message,
                        { timeout: 4000, rtl: true },
                        "error"
                    );
                }
            });
    }

    getTrips(payload) {
        let token = localStorage.getItem('nawader_access_token')
        let request = new Request('get', `/trip`, null, payload, token)
        this.tripsState.trigger(async () => {
            return await (await request.send());
        },
            {
                onSuccess: (value) => {
                    this.setTripsData(value)

                },
                onError: (error) => {
                    console.log(error)
                }
            });
    }
    setRouteData(data) {
        this.driversTable.tableData = data.routes.map((el)=>({
            ...el,
            selected: false,
        }))
        this.driversTable.tableMeta.total = data.meta.total
        this.driversTable.tableMeta.page = data.meta.current_page
        this.driversTable.loading = this.routeState.loading


    }
    setStudentsRouteData(data) {
        this.studentsRouteTable.tableData = data.students
        this.studentsRouteTable.tableMeta.total = data.meta.total
        this.studentsRouteTable.tableMeta.page = data.meta.current_page
        this.studentsRouteTable.tableMeta.perPage = data.meta.per_page
        this.studentsRouteTable.loading = this.studentsInRouteState.loading
    }
    setTripsData(data) {
        this.tripsTable.tableData = data.trips.data
        this.tripsTable.tableMeta.total = data.trips.total
        this.tripsTable.tableMeta.page = data.trips.current_page
        this.tripsTable.tableMeta.perPage = data.trips.per_page
    }
}
export const driverRoute = new driverRoutes()