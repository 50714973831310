import axios from 'axios'
const state = {
    tableOptions: {
        tableHeaders: [],
        tableData: [],
        accessTableData: [],
        loading: false,
        tableMeta: {
            total: 10,
            page: 1,
        },
    }
}
const getters = {
    getTableOptions(state) {
        return state.tableOptions
    },
}
const mutations = {
    setLoading(state, status) {
        state.tableOptions.loading = status
    },
    setTableData(state, tableInfo) {
        state.tableOptions.tableHeaders = tableInfo.tableHeaders
        state.tableOptions.tableData = tableInfo.tableData
        state.tableOptions.accessTableData = tableInfo.accessTableData
        state.tableOptions.tableMeta.total = tableInfo.tableMeta.total
        state.tableOptions.tableMeta.page = tableInfo.tableMeta.page
    }
}
const actions = {
    async fetchAdmins({ commit }, payload) {
        try {
            commit('setLoading', true)
            let params = {
                size: 10,
            };
            for (let key in payload.params) {
                if (payload.params[key]) {
                    params[key] = payload.params[key]
                }
            }
            const response = await axios.get('/user', {
                params,
            });
            let tableHeaders = []
            let accessTableData = []
            tableHeaders = ["id", "name", "user name","Role","School Name","phone number", "settings"]
            accessTableData = [["id"], ["name"], ["username"],["role","name"],["school","name"],["phone"]]
            let tableData = response.data.data.map((el)=>{
                return {
                    id: el.id,
                    name: el.name,
                    username: el.username,
                    role: el.role,
                    school: el.school,
                    phone: el.phone,
                }

            });
            commit('setTableData', {
                tableHeaders,
                tableData: tableData,
                accessTableData,
                tableMeta: {
                    total: response.data.meta.total,
                    page: response.data.meta.current_page,
                },
            })
        } catch (err) {
            console.log('err', err);
        } finally {
            commit('setLoading', false)
        }
    },
}


export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true
}